import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FeaturesV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    let customClass = this.props.customClass ? this.props.customClass : "";

    return (
      <div className={customClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">Why Choose Bramhmaputra?</h1>
                {/* <h1 className="section-title">Our Main Focus</h1> */}
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl5qEUDBKF0QLszo_p840X6yYd5UEc6kO2SKTgM9jgVv2XsGKe4TlT4byM10jLsEvRxvE&usqp=CAU"
                    }
                    alt="#"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Wide Range of Projects</Link>
                  </h3>
                  <p>
                    With over nine years of expertise in the real estate
                    industry, we bring extensive knowledge and valuable insights
                    to every project, ensuring unparalleled quality and
                    professionalism.
                  </p>
                  {/* <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9kktMoY-Hq9IV_FLRUwxM6W93Mu2riweTXnCL9OiTXmtjxTqgeeQ70QivFKi6qZ1dF_s&usqp=CAU"
                    }
                    alt="#"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Success Across All Sectors</Link>
                  </h3>
                  <p>
                    An extensive portfolio of successful projects across
                    residential, commercial, and industrial sectors.
                  </p>
                  {/* <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsv_u6du5tKw-99ggxmgTqsKhgfN0DZhwGfZdpj1HXds7ryFOhpfiNU7b42bVv7iS3z44&usqp=CAU"
                    }
                    alt="#"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Committed to Client Success</Link>
                  </h3>
                  <p>
                    A dedicated team committed to client satisfaction and
                    ensuring timely project delivery in custom builds.
                  </p>
                  {/* <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0_LCdQFQw6XUsT9O_4gK9XR3jrqKHZ42FAw&s"
                    }
                    alt="#"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#	">Turning Your Vision Into Reality</Link>
                  </h3>
                  <p>
                    Let us bring your vision to life. We believe in building
                    strong relationships and legacies, not merely structures.
                  </p>
                  {/* <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      "https://cdn-icons-png.flaticon.com/512/4813/4813156.png"
                    }
                    alt="#"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Our Mission</Link>
                  </h3>
                  <p>
                    To build innovative structures that seamlessly blend
                    functionality with aesthetics while adhering to the highest
                    standards of quality, safety, sustainability, craftsmanship,
                    and timeless design.
                  </p>
                  {/* <Link className="ltn__service-btn" to="#">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOt-LZFx4GZLBjMXWwcG9sE0q4cxx8uE6jX63XccCSHeXkyOPDQ5ixlJ0F5YVNknqfxJ0&usqp=CAU"
                    }
                    alt="#"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Values</Link>
                  </h3>
                  <p>
                    Excellence: Delivering unmatched craftsmanship in every
                    project.
                    <br />
                    Integrity: Upholding transparency and trust with all
                    stakeholders.
                  </p>
                  <p></p>
                  {/* <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesV1;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
// import plotlayout1 from '../../assets/plotlayout1.png'
import constructing1 from "../../assets/constructing1.jpeg";
import home1 from "../../assets/home1.jpeg";

class ApartmentV2 extends Component {
  render() {
    let CustomClass = this.props.customClass ? this.props.customClass : "";

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className={"ltn__apartments-plan-area pt-115--- pb-70 " + CustomClass}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">
                  {/* Apartments Plan */}
                  Our Process
                  {/* How we work */}
                </h1>
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  {/* Our Ongoing Projects/ */}
                  {/* Our process<br/> */}
                  We help you experience a smooth and transparent journey to
                  your dream home with our simple process: Progress – Finalize.
                </h6>
              </div>
              <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav">
                  <a data-bs-toggle="tab" href="#liton_tab_3_1">
                    {/* Alexa City */}
                    Booking
                  </a>
                  <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_2"
                  >
                    {/* Luxxa City */}
                    Requirements
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_3">
                    {/* Ahuja Exoticaa */}
                    Design and Planning
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_4">
                    {/* Swami nagar */}
                    Home Construction
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_5">
                    {/* Our Homes */}
                    Move-in
                  </a>
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade" id="liton_tab_3_1">
                  <div className="ltn__apartments-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          {/* <h2>The Studio</h2> */}
                          <h2>Booking</h2>
                          <p>
                            Lock in your building construction project with a
                            minimal booking fee. This guarantees a fixed price
                            per square foot, protecting you from any cost
                            changes until the project is completed. Upon
                            booking, you’ll receive direct contact details for
                            our team and gain exclusive access to a
                            state-of-the-art project dashboard. Through this
                            dashboard, you can track progress and receive
                            consistent updates from our expert construction
                            team.
                            {/* At Brahmaputra Civil Construction, the first step in
                            building your dream space begins with raising a
                            request. This involves a detailed discussion about
                            your vision for the project, ensuring your unique
                            lifestyle and daily needs are at the heart of the
                            design. We collaborate closely to understand how the
                            space will support your activities, making it both
                            functional and inspiring. Budget considerations are
                            a key focus, as we work to align the project with
                            your financial goals without compromising on quality
                            or ambition. Additionally, we thoroughly evaluate
                            factors such as site location, orientation, and any
                            specific requirements to lay the foundation for a
                            personalized, practical, and visionary construction
                            plan tailored just for you. */}
                            <br />
                            We utilize high-quality materials and adhere to
                            stringent quality checks throughout the construction
                            process.
                            <br />
                            Ensure that safety and durability standards are met
                            at every stage of the construction process.
                            {/* a compact yet elegant space designed for modern
                            living. Perfect for professionals, small families,
                            or those seeking a serene retreat, The Studio offers
                            a seamless blend of style, functionality, and
                            comfort. */}
                          </p>
                          {/* <div className="apartments-info-list apartments-info-list-color mt-40"> */}
                          {/* <ul> */}
                          {/* <li> */}
                          {/* <label> */}{" "}
                          {/* Discuss your vision, lifestyle, and budget. */}
                          {/* </label>{" "} */}
                          {/* <span>2800 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li>
                                <label>
                                  {" "}
                                  Identify key features and functionalities you
                                  desire
                                </label>
                                {/* <span>150 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li> */}
                          {/* </ul> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          {/* <img
                            src={publicUrl + "assets/img/others/10.png"}
                            alt="#"
                          /> */}
                          <img
                            src={
                              publicUrl + "assets/img/new images/booking.jpg"
                            }
                            // src="https://media.istockphoto.com/id/1397370316/vector/business-meeting-in-the-office-with-laptop-vector-illustration.jpg?s=612x612&w=0&k=20&c=IyR9gakjecBEZi44z14DfVezqq3kT_yRig1jwDi9TDo="
                            alt="#"
                            width="600"
                            height="500"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade active show" id="liton_tab_3_2">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>Raise a request </h2>
                          {/* <h2>Deluxe Portion</h2> */}
                          <p>
                            {/* Secure your building construction project with a
                            minimal booking fee, guaranteeing a fixed price per
                            square foot. This ensures you are protected from any
                            unexpected cost fluctuations throughout the duration
                            of the project. Once you book, you’ll receive a
                            unique contact number or WhatsApp link for easy
                            communication, where you can reach out directly for
                            updates or inquiries. Our state-of-the-art project
                            dashboard also provides you with the ability to
                            track progress in real-time and receive consistent
                            updates from our expert construction team. We are
                            committed to using only high-quality materials and
                            following strict quality checks at every stage of
                            the construction process, ensuring that safety and
                            durability standards are met, while bringing your
                            vision to life with precision and excellence. */}
                            A home is a place where families evolve and
                            experience change. Our process begins with a
                            detailed discussion of your requirements – whether
                            it’s design, layout, lifestyle or the space that
                            will cater to your daily activities. Budget
                            considerations are carefully assessed to ensure the
                            project aligns with your financial goals.
                            Additionally, factors such as site location,
                            orientation, and any other specific requirements are
                            thoroughly evaluated to create a solid foundation
                            for a personalized and practical construction plan.
                          </p>
                          {/* <div className="apartments-info-list apartments-info-list-color mt-40"> */}
                          {/* <ul> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Use high-quality materials and follow */}
                          {/* stringent quality checks. */}
                          {/* Total Area */}
                          {/* </label>{" "} */}
                          {/* <span>2800 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Ensure safety and durability standards are met */}
                          {/* at every step. */}
                          {/* Bedroom */}
                          {/* </label> */}
                          {/* <span>150 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li> */}
                          {/* </ul> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={
                              publicUrl +
                              "assets/img/new images/istockphoto.jpg"
                            }
                            alt="#"
                            style={{ height: "580px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_3">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          {/* <h2>Penthouse</h2> */}
                          <h2>Design and Planning</h2>
                          <p>
                            When you collaborate with our team of skilled
                            architects, we help you create a home that reflects
                            your style and fulfils all functional needs. Our
                            design process integrated to craft precise
                            architectural plans, which are then handed over to
                            our experienced Civil Engineers for the creation of
                            Structural and MEP (Mechanical, Electrical, and
                            Plumbing) Drawings. We ensure a smooth transition
                            into the construction phase after design approvals.
                            We work together to finalize layouts, elevations,
                            and interior designs, while also planning a
                            realistic timeline and securing all necessary
                            permits and approvals to ensure a hassle-free
                            building experience.
                            {/* Collaborate with the our highly skilled architects
                            to design your home that reflects your personal
                            style and functional needs. Our design process
                            incorporates the latest technology to develop
                            precise architectural plans, which are then handed
                            over to our experienced Civil Engineers for
                            Structural and MEP Drawings. Upon approval of these
                            plans, Our AI seamlessly schedules the project and
                            transitions it to the construction phase. */}
                            {/* The Penthouse offers an exclusive living experience
                            that blends sophistication with panoramic views.
                            Designed for those who seek the pinnacle of luxury
                            and comfort, this space is perfect for those who
                            appreciate the finer things in life. */}
                            {/* Discover the epitome of luxury living with our
                            Penthouse, a sanctuary in the skies offering
                            unparalleled elegance and breathtaking views.
                            Designed for those who desire privacy and
                            sophistication, this exquisite space combines style
                            with modern functionality. */}
                          </p>
                          {/* <div className="apartments-info-list apartments-info-list-color mt-40"> */}
                          {/* <ul> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Finalize layouts, elevations, and interiors. */}
                          {/* Total Area */}
                          {/* </label>{" "} */}
                          {/* <span>2800 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Plan a timeline and obtain necessary permits */}
                          {/* and approvals. */}
                          {/* Bedroom */}
                          {/* </label> */}
                          {/* <span>150 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li> */}
                          {/* </ul> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          {/* <img
                            src={publicUrl + "assets/img/others/10.png"}
                            alt="#"
                          /> */}
                          <img
                            // src={plotlayout1}
                            src={
                              publicUrl +
                              "assets/img/new images/plotlayout1.png"
                            }
                            alt="#"
                            style={{ height: "600px", width: "600px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_4">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          {/* <h2>Top Garden</h2> */}
                          <h2>Home Construction</h2>
                          <p>
                            Experience the seamless construction of your dream
                            home with Brahmaputra Civil Construction, where our
                            team brings your vision to life and a commitment to
                            the high standards of quality. We conduct continuous
                            quality checks to ensure every aspect of your home
                            meets our rigorous standards. The process begins
                            with site preparation, foundation work, and
                            structural construction, followed by supervision of
                            plumbing, electrical, and interior finishing. We
                            ensure transparency and progress tracking with
                            consistent updates throughout the project.
                            {/* <br />
                            The process begins with site preparation, foundation
                            work, and structural construction, followed by
                            supervision of plumbing, electrical, and interior
                            finishing. We ensure transparency and progress
                            tracking with consistent updates throughout the
                            project. */}
                            {/* Experience the construction of your dream home with
                            our skilled team, utilizing state-of-the-art
                            technology to ensure the highest standards of
                            quality and safety. Benefit from continuous
                            inspections with over 440+ quality checks, and stay
                            informed with regular updates through your real-time
                            construction project dashboard. */}
                            {/* Welcome to the Top Garden, an oasis of tranquility
                            and greenery located atop our premium development.
                            Designed for those who appreciate nature and a
                            serene environment, the Top Garden offers a peaceful
                            retreat with stunning panoramic views. */}
                          </p>
                          {/* <div className="apartments-info-list apartments-info-list-color mt-40"> */}
                          {/* <ul> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Begin with site preparation, foundation work, */}
                          {/* and structural construction. */}
                          {/* </label> */}
                          {/* <span>2800 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Supervise plumbing, electrical, and interior */}
                          {/* finishing. */}
                          {/* </label> */}
                          {/* <span>150 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Maintain regular updates to ensure */}
                          {/* transparency and progress tracking. */}
                          {/* </label> */}
                          {/* <span>45 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li> */}
                          {/* </ul> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src="https://img.freepik.com/premium-vector/couple-kids-wearing-helmets-visiting-building-site_82574-9959.jpg?semt=ais_hybrid"
                            // src={publicUrl + "assets/img/new images/constructing1.jpeg"}
                            alt="#"
                            style={{ height: "600px", width: "600px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_5">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>Welcome to your new home</h2>
                          <p>
                            Enjoy the peace of mind that comes with a
                            comprehensive structural warranty on your new home.
                            At Brahmaputra Construction, our dedication to
                            quality and customer satisfaction doesn’t end with
                            the completion of the project. We are committed to
                            being there for you even after the construction is
                            finished. Our experienced team is available to
                            assist with any service or maintenance needs that
                            may arise. For added protection, you can also opt
                            for our advanced maintenance package, ensuring the
                            long-term durability and upkeep of your home. Before
                            finalizing the handover, we conduct a thorough
                            walkthrough to ensure your complete satisfaction
                            with every detail. Once you're fully satisfied, we
                            hand over the keys along with all necessary
                            documents and guarantees, marking the official
                            completion of your project. It’s time to celebrate
                            your journey and settle into the home you've always
                            dreamed of.
                            {/* Enjoy the peace of mind that comes with a
                            comprehensive structural warranty on your new home.
                            At Brahmaputra Civil Construction, our dedication to
                            quality and customer satisfaction doesn’t end with
                            the completion of the project. We are committed to
                            being there for you even after the construction is
                            finished. Our experienced team is available to
                            assist with any service or maintenance needs that
                            may arise. For added protection, you can also opt
                            for our advanced maintenance package, ensuring the */}
                            {/* long-term durability and upkeep of your home.<br /> */}
                            {/* Enjoy the peace of mind that comes with a 10-year
                            structural warranty on your new home. Our dedication
                            to quality and customer satisfaction extends beyond
                            the completion of the project. After construction,
                            our experienced team is here to assist with any
                            service or maintenance needs. For added protection,
                            you can also choose our advanced maintenance
                            package, ensuring the long-term durability and
                            upkeep of your home. */}
                            {/* Step into grandeur with the Double Height, where
                            expansive space and sophisticated design come
                            together. This feature offers an open, airy
                            environment that brings a sense of luxury and
                            comfort to your living experience. */}
                          </p>
                          {/* <div className="apartments-info-list apartments-info-list-color mt-40"> */}
                          {/* <ul> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Conduct a final walkthrough to ensure */}
                          {/* satisfaction. */}
                          {/* </label>{" "} */}
                          {/* <span>2800 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Handover keys with all necessary documents and */}
                          {/* guarantees. */}
                          {/* </label> */}
                          {/* <span>150 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li> */}
                          {/* <label> */}
                          {/* Celebrate your journey and settle into your */}
                          {/* dream home. */}
                          {/* </label> */}
                          {/* <span>45 Sq. Ft</span> */}
                          {/* </li> */}
                          {/* <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li> */}
                          {/* </ul> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src="https://media.istockphoto.com/id/1429196285/vector/family-house-single-family-detached-home-family-house-single-dwelling-unit-townhouse-private.jpg?s=612x612&w=0&k=20&c=-8-RWpuWEOcQ6mqo2P2Rw99Qup0lEOPAAJIh1Fs0bG4="
                            // src={publicUrl + "assets/img/new images/home1.jpeg"}
                            alt="#"
                            style={{ height: "600px", width: "600px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApartmentV2;

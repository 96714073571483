import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-115 pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/HomeAbout.png"}
                  alt="About Us Image"
                />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src={publicUrl + "assets/img/others/8.png"}
                      alt="video popup bg image"
                    />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title">
                  <h1 className="section-title">About Us</h1>
                  {/* <h6 className=" section-subtitle section-subtitle-2--- ltn__secondary-color">
                    Dream Living Spaces Setting New Build
                  </h6> */}
                  <p>
                    {" "}
                    Welcome to{" "}
                    <span className="font-semibold">
                      Brahmaputra Construction.
                    </span>
                    <br />
                    We are your trusted partners in navigating the property
                    market, and we believe in honesty and transparency in every
                    customer interaction. Since our establishment in 2015,
                    Brahmaputra Construction has established itself as a
                    reputable name in delivering excellence and innovation in
                    the real estate industry.
                  </p>
                  {/* <p className="text-lg text-gray-700 text-center mb-4">
                    We believe in honesty and transparency in every customer
                    interaction.
                  </p>
                  <p className="text-lg text-gray-700 text-center">
                    Since our inception in 2015, Brahmaputra Construction
                    Company has been a trusted name in delivering excellence and
                    innovation in the real estate industry.
                  </p> */}
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      {/* <i className="flaticon-house-4" /> */}
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="#">The Perfect Residency</a>
                    </h4>
                    <p>
                      A landmark project by Brahmaputra Construction offers a
                      seamless blend of luxury, comfort, and modern living,
                      tailored to create your perfect lifestyle.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      {/* <i className="flaticon-call-center-agent" /> */}
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="#"> Architect Experts</a>
                    </h4>
                    <p>
                    By partnering with Brahmaputra Construction, we deliver remarkable designs that redefine innovation and excellence in construction. 
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      {/* <i className="flaticon-maps-and-location" /> */}
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="#">Built-In Storage Compartments</a>
                    </h4>
                    <p>
                    Brahmaputra Construction ensures smart, space – saving solutions with elegant and functional cupboard designs tailored to meet your needs. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV3;

import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import Features from './section-components/features-v1';
import Team from './section-components/team-v1';
// import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import Aboutv2 from './section-components/about-v2';
import Aboutleft from './section-components/about-left';
import Aboutright2 from './section-components/about-right2';
import CallToActonV2 from './section-components/call-to-action-v2';
import Counter from './section-components/counter-v1';
const About_v1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="About Us" />
        <AboutV4 />
        <div className="text-center mt-3">
                <h1 style={{ display: 'inline-block', margin: 0 }}>Our Projects</h1>
                <hr style={{
                    border: 'none',
                    borderTop: '2px solid black',
                    width: '13%',
                    margin: '10px auto 0',
                    display: 'block' 
                }} />
            </div>
        <Aboutv2 />
        <Aboutleft />
        {/* <Aboutright2 /> */}
        <CallToActonV2/>
        {/* <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/> */}
        {/* <Team /> */}
        {/* <Testimonial /> */}
        {/* <BlogSlider /> */}
        <Counter />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default About_v1


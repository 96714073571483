import React from "react";
import Navbar from "./global-components/navbar-v2";
// import PageHeader from './global-components/page-header';
import BlogLeft from "./blog-components/blog-list";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Portfolio from "./section-components/portfolio";
import PageHeader from "./global-components/page-header";

const BlogLeftSidebar = () => {
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Blog Left Sidebar" subheader="Blog" /> */}
      <PageHeader headertitle="Our Projects" subheader="Our Project" />
      <BlogLeft />
      <div className="text-center mt-3">
        <h1 style={{ display: "inline-block", color: "#FF5A3C", margin: 0 }}>
          Contact Us
        </h1>
        <hr
          style={{
            border: "none",
            borderTop: "2px solid black",
            width: "13%",
            margin: "10px auto 0",
            display: "block",
          }}
        />
      </div>
      <div
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  }}
>
  <FontAwesomeIcon
    icon={faWhatsapp}
    size="3x"
    style={{ color: "#25D366" }}
  />{" "}
  <span style={{ fontWeight: "bold" , fontSize: "30px"}}>7000800337</span>
</div>


      <div className="text-center mt-5">
        <h1 style={{ display: "inline-block", color: "#FF5A3C", margin: 0 }}>
          {/* Actual Images */}
          Our Designs 
        </h1>
        <hr
          style={{
            border: "none",
            borderTop: "2px solid black",
            width: "13%",
            margin: "10px auto 0",
            display: "block",
          }}
        />
      </div>
      <Portfolio />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default BlogLeftSidebar;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV5 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left about-img-left">
                <img
                  // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWuzz69o6fo6u7nnZNS-hpMZfBJKY_6ugQUpijZlam5gL2Ahp-WDmLpZxU9POkvfSArNY&usqp=CAU"
                  src={publicUrl + "assets/img/new images/bgabout2.jpeg"}
                  alt="Image"
                  style={{ width: "500px", height: "600px" }}
                />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    Brahmaputra Construction <span>.</span>
                  </h1>
                  <p>
                    Leading Real Estate Rental Marketplace, a platform designed
                    to simplify your property search and rental experience. With
                    a user-friendly interface, it allows you to explore a wide
                    range of rental properties tailored to meet diverse needs
                    and preferences. Whether you’re seeking luxurious
                    apartments, spacious homes, or commercial spaces, our
                    marketplace provides detailed listings, high-quality images,
                    and key information to help you make informed decisions.
                  </p>
                </div>
                <div className="about-us-info-wrap-inner about-us-info-devide---">
                  <p>
                    In addition to offering seamless property searches, our
                    platform is equipped with advanced tools like custom forms
                    and lead management panels, enabling property owners and
                    agents to capture inquiries and maintain a comprehensive
                    record of all interactions. Brahmaputra Construction’s
                    commitment to quality and innovation ensures that you enjoy
                    a hassle-free journey to finding your ideal rental property.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link
                    to="/about"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    About Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV5;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import pic6 from "../../assets/pic6.jpg";
class BlogList extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div className="ltn__blog-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-lg-2">
              <div className="ltn__blog-list-wrap">
                {/* Blog Item */}
                <div className="ltn__blog-item ltn__blog-item-5 go-top d-flex">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="ltn__blog-img mt-lg-5">
                      <Link to="/blog-details">
                        <img
                          src={pic6}
                          alt="Image"
                          style={{ height: "400px", width: "600px" }}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/blog-details">
                          Welcome to Our Horizon Heights
                        </Link>
                      </h3>

                      <p>
                        Discover the perfect blend of modern living and timeless
                        design at Horizon Heights, a landmark project by Brahmaputra
                        Construction. Spanning 3 acres of meticulously planned
                        development, Horizon Heights is more than just a residential
                        project; it is a thriving community designed to inspire
                        a better way of life.
                      </p>
                      <h5 className="mt-1">Total 3 Acres</h5>
                      <h5 className="mt-1">Project Duration 2011 - 2014</h5>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/blog-details">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-12">
                  <h2 className="ms-lg-3" style={{ color: "#FF5A3C" }}>
                    AMENITIES
                  </h2>
                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="col-lg-3">
                      <h6>Grand Gentry Enterance</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Commercial Units/Shops</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Water Supply</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Kids Play Area</h6>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="col-lg-3">
                      <h6>Soalr Lighting</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Open Air Gym</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>24 Hours Water Supply</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Jogging Track- Footpath</h6>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-12 mt-5">
                  <h2 className="ms-lg-3" style={{ color: "#FF5A3C" }}>
                    SPECIFICATIONS
                  </h2>
                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="col-lg-3">
                      <h6>R.C.C.. Framed Structure</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>AAC Blocks Walls</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Teak Entry Door</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Vitrified Tiled</h6>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="col-lg-3">
                      <h6>Modular Kitchen Platform</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Branded Bath Fitting</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>ISI Electrical Goods</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Internal Flush Door</h6>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="col-lg-3">
                      <h6>Branded Putty And Painting</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>S.S.. Stair Railing</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>UPVC / Aluminium Windows</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Glass & Stainless Steel Railing At Elevation</h6>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="col-lg-3">
                      <h6>Testure At Elevation</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Stair Floor of Green Marble</h6>
                    </div>

                    <div className="col-lg-3">
                      <h6>Safety Grills</h6>
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Sidebar/> */}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogList;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Comments from './comments';
import Sidebar from './sidebar';
class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
		<div className="ltn__page-details-area ltn__blog-details-area mb-120">
			<div className="container">
			<div className="row">
				<div className="col-lg-8">
				<div className="ltn__blog-details-wrap">
					<div className="ltn__page-details-inner ltn__blog-details-inner">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-category">
							<Link to="/shop">Real Estate</Link>
						</li>
						</ul>
					</div>
					<h2 className="ltn__blog-title">Real estate is property consisting of land and the buildings on it, 
						along with its
					</h2>
					{/* <div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" />By: Ethan</Link>
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />June 22, 2020
						</li>
						<li>
							<Link to="#"><i className="far fa-comments" />35 Comments</Link>
						</li>
						</ul>
					</div> */}
					<p>Real estate refers to property that includes land and any structures built upon it, such as homes, commercial buildings, or other types of infrastructure. It also encompasses the natural resources on the land, like water, minerals, and crops.Real estate is a valuable asset class, playing a significant role in both personal wealth and the economy, with properties being bought, sold, or leased for residential, commercial, or industrial purposes. Its value is influenced by factors such as location, market trends, and the condition of the property.</p>
					<p>Real estate is a broad term that encompasses land, buildings, and natural resources found on the property. It plays a key role in various industries, including residential, commercial, industrial, and agricultural sectors. The real estate market is often influenced by local and global economic conditions, population growth, urban development, and governmental policies. Beyond buying and selling, real estate also includes renting and leasing, which allows individuals and businesses to access property for various purposes without owning it.</p>
					<img src={publicUrl+"assets/img/blog/blog1.jpg"} alt="Image" />
					<h2>A cleansing hot shower or bath</h2>
					<p>As a tangible asset, real estate can provide long-term value, income through rental properties, and significant capital appreciation. It is also a common investment choice due to its stability and potential for wealth generation. </p>
					<hr />
					<h2>Setting the mood with incense</h2>
					<p>
					Setting the mood with incense is a simple yet effective way to enhance the ambiance of any space. The soft, aromatic smoke from incense can create a calm, soothing atmosphere, making it perfect for relaxation, meditation, or unwinding after a busy day. Incense comes in a variety of scents, such as lavender, sandalwood, jasmine, and frankincense, each with its own unique properties. For instance, lavender is known for its calming and stress-relieving effects, while sandalwood promotes focus and spiritual well-being. Whether used for a peaceful night at home or during yoga practice, incense can transform a room into a serene sanctuary, evoking a sense of tranquility and mindfulness. </p>
					<hr />
					<h2>Enhancing Your Space with Aroma</h2>
					<div className="list-item-with-icon-2">
						<ul>
						<li>Creates a Calming Atmosphere</li>
						<li>Enhances Focus and Concentration</li>
						<li>Supports Meditation and Yoga</li>
						<li>Variety of Scents for Different Moods</li>
						<li>Aromatherapy Benefits</li>
						</ul>
					</div>
					<blockquote>
						<h6 className="ltn__secondary-color mt-0">BY HETMAYAR</h6>
						Viral dreamcatcher keytar typewriter, aest hetic offal umami. Aesthetic polaroid pug pitchfork post-ironic.
					</blockquote>
					<p>Enhancing your space with aroma is a powerful way to transform the ambiance of any room, creating an environment that reflects your mood and intentions. The use of scents, whether through candles, essential oils, or incense, can invoke feelings of calm, energy, or focus. Aromas like lavender and chamomile promote relaxation, making them perfect for bedrooms or areas meant for unwinding, while invigorating scents like citrus or peppermint can energize a space, boosting productivity and alertness. By carefully selecting fragrances that align with your needs, you can improve the atmosphere, enhance your well-being, and make your home feel more inviting and harmonious. </p>
					<img className="alignleft" src={publicUrl+"assets/img/blog/blog-details/blogdetailttop1.jpg"} alt="Image" />
					<p>Enhancing your space with aroma goes beyond just pleasant scents—it's about creating an atmosphere that nurtures your emotions and enhances your daily experience. Scents have the ability to influence mood, boost productivity, and even improve sleep quality. For example, lavender and eucalyptus can help reduce stress and promote relaxation, ideal for spaces where you unwind, such as the bedroom or bathroom.</p>
					<p>On the other hand, invigorating scents like lemon or rosemary are perfect for workspaces or areas where you need to stay alert and focused. Fragrances can also evoke memories, adding a nostalgic or calming touch to your surroundings. Whether through the use of diffusers, incense, or scented candles, the right aromas can help create a welcoming, tranquil, and balanced environment, making your space truly feel like home.</p>
					<h4> Refreshing Power of a Cleansing Hot Shower or Bath</h4>
					<p>A cleansing hot shower or bath is a simple yet effective way to unwind and rejuvenate both the body and mind. The warmth of the water helps relax tense muscles, improve circulation, and soothe aches and pains. As you step into the steam, it not only opens up your pores but also offers a sense of mental clarity and stress relief.</p>
					<div className="row">
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/blog/blog-details/blogdetaillbottom1.jpg"} alt="Image" />
						<label>Image Caption Here</label>
						</div>
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/blog/blog-details/blogdetailbottom2.jpg"} alt="Image" />
						</div>
					</div>
					<p>Adding essential oils, bath salts, or a few drops of your favorite fragrance can further enhance the experience, promoting relaxation and a sense of calm. Whether it's a quick shower to start the day or a long, soothing bath to end it, this ritual offers a refreshing escape, leaving you feeling revitalized and ready to face whatever comes next.</p>
					</div>
					{/* blog-tags-social-media */}
					<div className="ltn__blog-tags-social-media mt-80 row">
					<div className="ltn__tagcloud-widget col-lg-8 go-top">
						<h4>Releted Tags</h4>
						<ul>
						<li>
							<Link to="/shop">Popular</Link>
						</li>
						<li>
							<Link to="/shop">Business</Link>
						</li>
						<li>
							<Link to="/shop">ux</Link>
						</li>
						</ul>
					</div>
					<div className="ltn__social-media text-right text-end col-lg-4">
						<h4>Social Share</h4>
						<ul>
						<li><a href="https://business.facebook.com/latest/home?asset_id=488217714365072" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
						</ul>
					</div>
					</div>
					<hr />
					{/* prev-next-btn */}
					{/* <div className="ltn__prev-next-btn row mb-50">
					<div className="blog-prev col-lg-6">
						<h6>Prev Post</h6>
						<h3 className="ltn__blog-title"><Link to="#">Tips On Minimalist</Link></h3>
					</div>
					<div className="blog-prev blog-next text-right text-end col-lg-6">
						<h6>Next Post</h6>
						<h3 className="ltn__blog-title"><Link to="#">Less Is More</Link></h3>
					</div>
					</div>
					<hr /> */}
					{/* related-post */}
					{/* <div className="related-post-area mb-50">
					<h4 className="title-2">Related Post</h4>
					<div className="row">
						<div className="col-md-6">
						
						<div className="ltn__blog-item ltn__blog-item-6">
							<div className="ltn__blog-img">
							<Link to="/blog-details"><img src={publicUrl+"assets/img/blog/blog-details/11.jpg"} alt="Image" /></Link>
							</div>
							<div className="ltn__blog-brief">
							<div className="ltn__blog-meta">
								<ul>
								<li className="ltn__blog-date ltn__secondary-color">
									<i className="far fa-calendar-alt" />June 22, 2020
								</li>
								</ul>
							</div>
							<h3 className="ltn__blog-title"><Link to="/blog-details">A series of iOS 7 inspire
								vector icons sense.</Link></h3>
							<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
							</div>
						</div>
						</div>
						<div className="col-md-6">
						
						<div className="ltn__blog-item ltn__blog-item-6">
							<div className="ltn__blog-img">
							<Link to="/blog-details"><img src={publicUrl+"assets/img/blog/blog-details/12.jpg"} alt="Image" /></Link>
							</div>
							<div className="ltn__blog-brief">
							<div className="ltn__blog-meta">
								<ul>
								<li className="ltn__blog-date ltn__secondary-color">
									<i className="far fa-calendar-alt" />June 22, 2020
								</li>
								</ul>
							</div>
							<h3 className="ltn__blog-title"><Link to="/blog-details">A series of iOS 7 inspire
								vector icons sense.</Link></h3>
							<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
							</div>
						</div>
						</div>
					</div>
					</div> */}
					
				</div>
				</div>
				<Sidebar/>
			</div>
			</div>
		</div>
    )
  }
}

export default BlogDetails;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
// import home2 from'../../assets/home2.jpeg';
// import pic2 from '../../assets/pic2.jpg';
// import pic3 from '../../assets/pic3.jpg';
// import pic7 from '../../assets/pic7.jpg'

class Aboutleft extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120 pb-90 ">
			  <div className="container">
			    <div className="row">
                <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-right">
			          <img 
					//   src={home2} 
					src={publicUrl+"assets/img/new images/home2.jpeg"} 
					  alt="About Us Image" 
					  style={{width: '700px', height: '600px'}}
					  />
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Vijay Greens</h6>
			            <h1 className="section-title"> Sells Properties</h1>
			            <p>Explore available homes in Vijay Greens, where we offer spacious and stylish living spaces tailored to meet the demands of modern life.</p>
			          </div>
			          <ul className="ltn__list-item-1 ltn__list-item-1-before clearfix">
			            <li> Live Music Cocerts at Luviana</li>
			            <li>Our SecretIsland Boat Tour is Just for You</li>
			            <li>Our Secret Island Boat Tour</li>
			            <li>Live Music Cocerts at Luviana</li>
			          </ul>
			          <ul className="ltn__list-item-2 ltn__list-item-2-before ltn__flat-info">
			            <li><span>3 <i className="flaticon-bed" /></span>
			              Bedrooms
			            </li>
			            <li><span>2 <i className="flaticon-clean" /></span>
			              Bathrooms
			            </li>
			            <li><span>2 <i className="flaticon-car" /></span>
			              Car parking
			            </li>
			            <li><span>3450 <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
			              square Ft
			            </li>
			          </ul>
			          <ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
			            <li>
			              <a href={publicUrl+"assets/img/others/Aurachild1.jpg"} data-rel="lightcase:myCollection">
			                <img 
							// src={pic2} 
							src={publicUrl+"assets/img/new images/pic2.jpg"} 
							alt="Image" 
							style={{width: '700px', height: '120px'}}
							/>
			              </a>
			            </li>
			            <li>
			              <a href={publicUrl+"assets/img/others/Aurachild2.jpg"} data-rel="lightcase:myCollection">
			                <img
							//  src={pic3}
							src={publicUrl+"assets/img/new images/pic3.jpg"}  
							 alt="Image" 
							 style={{width: '700px', height: '120px'}}
							 />
			              </a>
			            </li>
			            <li>
			              <a href={publicUrl+"assets/img/others/Aurachild3.jpg"} data-rel="lightcase:myCollection">
			                <img 
							src={publicUrl+"assets/img/new images/pic9.jpg"} 
							alt="Image"
							style={{width: '700px', height: '120px'}}
							 />
							
			              </a>
			            </li>
			          </ul>
			        </div>
			      </div>
			      
			    </div>
			  </div>
			</div>
        }
}

export default Aboutleft
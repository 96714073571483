import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map mb-120 mt-150">
			
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.329354327663!2d79.91058647531902!3d23.194664879053562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981b1d3fc57705f%3A0x11a673271499fb33!2sShri%20Balaji%20Heights!5e0!3m2!1sen!2sin!4v1732534200716!5m2!1sen!2sin" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        }
}

export default Map
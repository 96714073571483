import React from "react";
import { Link } from "react-router-dom";
import video1 from  "../../assets/video1.mp4"
import bgabout2 from '../../assets/bgabout2.jpeg'
import pic from '../../assets/pic.jpg'


const AboutV4 = () => {
  const publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
      <div className="container">
        <div className="row">
          {/* Image Section */}
          <div className="col-lg-6 align-self-center">
            <div className="about-us-img-wrap about-img-left">
              <img
                // src={`${publicUrl}assets/img/others/Abouttop.png`}
                src={bgabout2}
                alt="Brahmaputra Construction - About Us"
                style={{ height: '800px', width:"800px" }} 
              />
              <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                <div className="ltn__video-img ltn__animation-pulse1">
                  <img
                  // src={`${publicUrl}assets/img/others/abouttopfixed.png`}
                    src={pic}
                    alt="Video popup background"
                  />
                  <a
                    className="ltn__video-icon-2 ltn__video-icon-2-border---"
                    href={video1}
                    //  href="https://www.youtube.com/watch?v=2WLd1zCVX9g"
                    aria-label="Play introduction video"
                  >
                    <i className="fa fa-play" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-lg-6 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  About Us
                </h6>
                <h1 className="section-title">
                  Brahmaputra Construction<span>.</span>
                </h1>
                <p>
                  Welcome to Brahmaputra Construction, your trusted partner
                  in navigating the Property Market. We believe in honesty
                  and transparency with every customer.
                </p>
              </div>
              <div className="ltn__callout bg-overlay-theme-05 mt-30">
                <p>
                  "Since our inception in 2015, Brahmaputra Construction
                  Company has been a trusted name in delivering excellence
                  and innovation in the real estate industry."
                </p>
              </div>
              <div className="btn-wrapper animated">
                <Link
                  to="/service"
                  className="theme-btn-1 btn btn-effect-1"
                >
                  OUR SERVICES
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutV4;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import bgabout from "../../assets/bgabout.jpg";
import pic4 from '../../assets/pic4.jpg'
// import pic5 from '../../assets/pic5.jpg'
import pic6 from '../../assets/pic6.jpg'

class AboutV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120 pb-90 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-30">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Skyline Vista
                  </h6>
                  <h1 className="section-title"> Sells Properties</h1>
                  <p>
                    Explore our selection of beautifully designed homes in Aura
                    City, perfect for those seeking comfort, style, and
                    convenience.
                  </p>
                </div>
                <ul className="ltn__list-item-1 ltn__list-item-1-before clearfix">
                  <li>Houses Platform</li>
                  <li>Our Secret Island Boat Tour </li>
                  <li>Live Music Cocerts at Luviana</li>
                  <li>Live Music Cocerts at Luviana</li>
                </ul>
                <ul className="ltn__list-item-2 ltn__list-item-2-before ltn__flat-info">
                  <li>
                    <span>
                      3 <i className="flaticon-bed" />
                    </span>
                    Bedrooms
                  </li>
                  <li>
                    <span>
                      2 <i className="flaticon-clean" />
                    </span>
                    Bathrooms
                  </li>
                  <li>
                    <span>
                      2 <i className="flaticon-car" />
                    </span>
                    Car parking
                  </li>
                  <li>
                    <span>
                      3450{" "}
                      <i className="flaticon-square-shape-design-interface-tool-symbol" />
                    </span>
                    square Ft
                  </li>
                </ul>
                <ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
                  <li>
                    <a href={bgabout} data-rel="lightcase:myCollection">
                      <img
                        src={publicUrl + "assets/img/new images/pic4.jpg"}
                        // src={pic4}
                        alt="Image"
                        style={{width: '700px', height: '120px'}}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={pic4}
                      data-rel="lightcase:myCollection"
                    >
                      <img
                        src={publicUrl + "assets/img/new images/pic5.jpg"}
                        // src={pic5}
                        alt="Image"
                        style={{width: '700px', height: '120px'}}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={pic6}
                      data-rel="lightcase:myCollection"
                    >
                      <img
                        src={publicUrl + "assets/img/new images/pic6.jpg"}
                        // src={pic6}
                        alt="Image"
                        style={{width: '700px', height: '120px'}}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-right">
                <img
                  // src={publicUrl + "assets/img/others/Auracity.png"}
                  // alt="About Us Image"
                  // src={bgabout}
                  src={publicUrl+"assets/img/new images/bgabout.jpg"} 
                  alt="About Us Image"
                  style={{width: '700px', height: '600px'}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV2;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import home1 from "../../assets/home1.jpeg";
import video1 from "../../assets/video1.mp4";
import bgabout from "../../assets/bgabout.jpg";
import about1 from "../../assets/about1.mp4";
import loby from "../../assets/loby.jpg";
import video2 from "../../assets/video2.mp4";
import video3 from "../../assets/video3.mp4";
import video4 from "../../assets/video4.mp4";
import video6 from "../../assets/video6.mp4";
import home2 from "../../assets/home2.jpeg";
import pic5 from "../../assets/pic5.jpg";
import pic3 from "../../assets/pic3.jpg";
import pic from "../../assets/pic.jpg";
import pic7 from "../../assets/pic7.jpg";
import pic9 from "../../assets/pic9.jpg";
import pic2 from "../../assets/pic2.jpg";
import constructing from "../../assets/constructing1.jpeg";
import lone from "../../assets/lone.jpg";

class PortfolioV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__gallery-area mb-120">
        <div className="container">
          {/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
          <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
            <div className="ltn__gallery-sizer col-1" />
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href={home1} data-rel="lightcase:myCollection">
                    <img
                      // src={home1}
                      src={publicUrl + "assets/img/new images/home1.jpeg"}
                      alt="Shindigs Bar And Grill"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fas fa-search" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Image Caption</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            {/* <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href="#ltn__inline_description_1" data-rel="lightcase:myCollection">
								<img src={publicUrl+"/assets/img/gallery/2.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="far fa-file-alt" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Inline Description </Link></h4>
							<p>Web Design &amp; Development, Branding</p>
							</div>
						</div>
						</div> */}
            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={video1}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={bgabout}
                      src={publicUrl + "assets/img/new images/bgabout.jpg"}
                      alt="YouTube Video Thumbnail"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details"> Video</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={about1}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={loby}
                      src={publicUrl + "assets/img/new images/loby.jpg"}
                      alt="YouTube Video Thumbnail"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details"> Video</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={video2}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={home2}
                      src={publicUrl + "assets/img/new images/home2.jpeg"}
                      alt="YouTube Video Thumbnail"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details"> Video</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={video3}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={pic2}
                      src={publicUrl + "assets/img/new images/pic2.jpg"}
                      alt="Vimeo Video Thumbnail"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details"> Video</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            {/* <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"/assets/media/1.mp4"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"/assets/img/gallery/5.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-video" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">HTML5 Video </Link></h4>
							<p>Web Design &amp; Development, Branding</p>
							</div>
						</div>
						</div> */}
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={video4}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={pic5}
                      src={publicUrl + "assets/img/new images/pic5.jpg"}
                      alt="Google Map Video Thumbnail"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Video</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={pic9}
                    data-rel="lightcase:myCollection"
                    data-bs-lc-caption="Your caption Here"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={pic}
                      src={publicUrl + "assets/img/new images/pic.jpg"}
                      alt="Image Caption"
                      style={{ height: "300px", width: "400px" }}
                    />

                    <span className="ltn__gallery-action-icon">
                      <i className="fas fa-search" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Image Caption</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={pic7}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={pic3}
                      src={publicUrl + "assets/img/new images/pic3.jpg"}
                      alt="Not Found Image"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fas fa-search" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Image Caption</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={pic3}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={pic7}
                      src={publicUrl + "assets/img/new images/pic7.jpg"}
                      alt="Portfolio Image"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fas fa-search" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Image Caption</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={pic2}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={pic2}
                      src={publicUrl + "assets/img/new images/lone.jpg"}
                      alt="Portfolio Image"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fas fa-search" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Image Caption</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={lone}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={pic9}
                      src={publicUrl + "assets/img/new images/pic9.jpg"}
                      alt="Portfolio Image"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fas fa-search" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Image Caption</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>

            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a
                    href={video6}
                    data-rel="lightcase:myCollection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      // src={constructing1}
                      src={publicUrl + "assets/img/new images/constructing1.jpeg"}
                      alt="Portfolio Image"
                      style={{ height: "300px", width: "400px" }}
                    />
                    <span className="ltn__gallery-action-icon">
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                </div>
                <div className="ltn__gallery-item-info">
                  <h4 className="go-top">
                    <Link to="/portfolio-details">Image Caption</Link>
                  </h4>
                  <p>Web Design &amp; Development, Branding</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div id="ltn__inline_description_1" style={{ display: "none" }}>
            <h4 className="first">
              This content comes from a hidden element on that page
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              quis mi eu elit tempor facilisis id et neque. Nulla sit amet sem
              sapien. Vestibulum imperdiet porta ante ac ornare. Nulla et lorem
              eu nibh adipiscing ultricies nec at lacus. Cras laoreet ultricies
              sem, at blandit mi eleifend aliquam. Nunc enim ipsum, vehicula non
              pretium varius, cursus ac tortor.
            </p>
            <p>
              Vivamus fringilla congue laoreet. Quisque ultrices sodales orci,
              quis rhoncus justo auctor in. Phasellus dui eros, bibendum eu
              feugiat ornare, faucibus eu mi. Nunc aliquet tempus sem, id
              aliquam diam varius ac. Maecenas nisl nunc, molestie vitae
              eleifend vel.
            </p>
          </div> */}
          <div className="btn-wrapper text-center">
            <Link
              to="#"
              className="btn btn-transparent btn-effect-3 btn-border"
            >
              LOAD MORE +
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioV1;

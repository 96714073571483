import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Page_header extends Component {

    render() {

        let HeaderTitle = this.props.headertitle;
        let publicUrl = process.env.PUBLIC_URL + '/';
        let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle;
        let CustomClass = this.props.customclass ? this.props.customclass : '';
        let Img = this.props.Img ? this.props.Img : '14.jpg';

        // Background image URL
        const backgroundImageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrcLRQ4wIz0XPCSiK7-ej5DR-aibm7HSfAlA&s";

        return (
            <div 
                className={"ltn__breadcrumb-area " + CustomClass} 
                style={{
                    backgroundImage: `url(${backgroundImageUrl})`,
                    backgroundSize: 'cover',       // Ensures the image covers the full width and height
                    backgroundPosition: 'center',  // Centers the image
                    backgroundRepeat: 'no-repeat'  // Prevents the image from repeating
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__breadcrumb-inner">
                                <h1 className="page-title text-center">{HeaderTitle}</h1>
                                <div className="ltn__breadcrumb-list text-center me-3">
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span className="ltn__secondary-color">
                                                    <i className="fas fa-home" />
                                                </span> Home
                                            </Link>
                                        </li>
                                        <li>{Subheader}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page_header;
